import { browser } from "$app/environment";

import { readable } from "svelte/store";

/**
 * Readable store which returns the number of milliseconds from the Unix epoch,
 * every animation interval after it is created.
 */
export const time = readable(Date.now(), (set) => {
  if (browser) {
    let animationFrame: number;
    const next = () => {
      set(Date.now());
      animationFrame = requestAnimationFrame(next);
    };
    next();
    return () => cancelAnimationFrame(animationFrame);
  }
});

/** Format a UTC date in the form "Day Month, Year" for display. */
export function formatDate(date: string) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date(date);
  const day = d.getUTCDate();
  const month = monthNames[d.getUTCMonth()];
  const year = d.getUTCFullYear();

  return `${month} ${day}, ${year}`;
}
